<!-- 角色管理 -->
<template>
    <div class="setBox">
        <!-- <div class="selectionBar">
            <div class="flex flex-ai-c flex-wrap">
                <label>场景：</label>
                <el-button type="primary" size="small" @click="edit()" style="margin-left:auto">
                    <i class="el-icon-circle-plus-outline"></i>
                    添加角色
                </el-button>
            </div>
        </div> -->

        <div class="table" style="height:calc( 100% - 110px )">
            <el-table ref="multipleTable" :data="list" :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" height="100%" style="width: 100%">
                <el-table-column prop="name" label="角色名称" show-overflow-tooltip></el-table-column>
                <!-- <el-table-column label="操作" width="180" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-edit" size="small" @click="edit(scope.row)"></el-button>
                            <el-button type="primary" icon="el-icon-delete" size="small" :disabled="scope.row.id == 1" @click="del(scope.row)"></el-button>
                        </el-button-group>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>

        <!-- 添加和编辑框 -->
        <el-dialog custom-class="nDialog" :visible="dialogOpen" :title="dialogTitle" :close-on-click-modal="false" width="600px" @close=" dialogOpen=false " :append-to-body="true" :destroy-on-close="true">
            <el-form ref="edit" :model="detail" :rules="rules" @submit.native.prevent label-width="80px">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="detail.name" size="small" placeholder="请输入角色名称"></el-input>
                </el-form-item>
                <el-form-item label="区域提成" prop="balance_is">
                    <el-radio v-model="detail.balance_is" :label="2">无提成</el-radio>
                    <el-radio v-model="detail.balance_is" :label="1">有提成</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogOpen=false">取 消</el-button>
                <el-button size="small" type="primary" @click="save()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            list: [],
            detail: {
                balance_is:2
            },
            dialogTitle: '',
            dialogOpen: false,
            rules: {
                name: [{
                    required: true,
                    message: '请输入角色名称',
                    trigger: 'blur'
                }],
                balance_is: [{
                    required: true,
                    message: '请选择是否有提成',
                    trigger: 'blur'
                }],
            }
        }
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList: function () {
            this.http.post('/admin.SystemRole/index').then(response => {
                this.list = response
            })
        },

        //保存
        save: function () {
            this.$refs['edit'].validate((valid) => {
                if (valid) {
                    this.http.post("/admin.SystemRole/save", this.detail).then(() => {
                        this.$message.success('保存成功');
                        this.dialogOpen = false;
                        this.getList();
                    })
                }
            })
        },

        //编辑
        edit: function (detail) {
            this.dialogTitle = detail ? '编辑角色' : '添加角色'
            
            this.detail = detail ? JSON.parse(JSON.stringify(detail)) : {balance_is:Number(2)}
            this.dialogOpen = true;
            console.log(this.detail);
        },

        //删除
        del: function (detail) {
            this.$confirm('确定要删除此角色吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/admin.SystemRole/delete', {
                    id: detail.id
                }).then(() => {
                    this.$message.success('已删除')
                    this.dialogOpen = false
                    this.getList()
                })
            }).catch(() => {
                this.$message.info('已取消删除');
            });
        }
    }
}
</script>
